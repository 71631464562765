export default {
    meds: 'MÉDICAMENTS',
    hygProds: 'PRODUITS DE SANTÉ',
  baseAuth: {
    later: 'Plus tard',
  },
  preSignup: {
    title: 'Pour obtenir des médicaments et des produits de santé, vous devez créer un compte en utilisant une adresse électronique. Vous n\'aurez pas besoin de fournir d\'autres informations.',
    agree: 'Ok',
  },
  signup: {
    title: 'Créer votre compte',
    repeatPassword: 'Écrivez encore une fois le mot de passe ',
    submit: 'Validez',
    agreeToTermsOfUse: 'En créant un compte, vous acceptez le',
    termsOfUse: 'Conditions d\'utilisation',
  },
  login: {
    title: 'Se connecter',
    email: 'e-mail',
    password: 'Mot de passe',
  },
  forgotPassword: {
    title: 'Mot de passe oublié ?',
    text: 'Saisissez l’adresse e-mail utilisée lors de la création de votre compte. On va vous envoyer des instructions pour entrer votre compte',
    cancel: 'Annuler',
    submit: 'Envoyer',
    successText: 'On vous a  envoyé des instructions pour définir votre mot de passe. Si vous n\'avez pas reçu d\'e-mail, veuillez confirmer que vous avez saisi l\'adresse e-mail utilisée lors de la création de votre compte et vérifiez le dossier de courrier indésirable.',
  },
  resetPassword: {
    title: 'Réinitialisation du mot de passe',
    password: 'Mot de passe',
    repeatPassword: 'Répéter le mot de passe',
    submit: 'Confirmer',
  },
  rules: {
    requiredField: 'This field is required',
    minLength: 'Tapez au moins 3 caractères',
    passwordLength: 'Le mot de passe doit avoir 8 caractères minimum.',
    emailValidation: 'Veuillez inscrire un e-mail valide.',
    passwordsNotMatch: 'Les mots de passe ne correspondent pas.',
    quantity: 'Invalid quantity',
    invalidBirthYear: 'Année de naissance invalide',
  },
  profile: {
    title: 'Informations personnelles',
    email: 'Email',
    language: 'Langue',
    appVersion: 'Version de application',
    regionalUnities: {
      title: 'Zones de réception des médicaments et des produits de santé',
      helpText: 'Sélectionnez autant de zones que vous le souhaitez. Si vous n\'en sélectionnez aucun, la recherche portera sur toutes les zones',
    },
    sex: 'Sexe',
    sexTypes: {
        male: 'Homme',
        female: 'Femme',
        nonBinary: 'Non-binaire',
        noneOfTheAbove: 'Aucune des options ci-dessus',
        noAnswer: 'Je préfère ne pas répondre',
    },
    birthYear: 'Date de naissance',
    changesSubmittedSuccessfully: 'Modifications soumises avec succès',
    notificationsEnabled: 'Les notifications sont activées',
    notificationsDisabled: 'Les notifications sont désactivées',
    changePassword: {
      title: 'Changement du mot de passe',
      currentPassword: 'Mot de passe actuel',
      newPassword: 'Nouveau mot de passe',
      repeatPassword: 'Répéter le mot de passe',
      submit: 'Confirmer',
      succesfulSubmittion: 'Le mot de passe a été changé avec succès',
    },
  },
  addNeeds: {
    title: 'Enregistrement des besoins',
    byName: {
      title: 'Recherche de médicaments',
      header: 'Entrez le nom du médicament souhaité et sélectionnez-le dans la liste',
      autocompleteLabel: 'Nom ou substance active du médicament',
    },
    bySubstance: {
      title: 'Recherche d\'une substance active',
      header: 'Tapez la combinaison de substances actives que vous voulez et sélectionnez-la dans la liste.',
      autocompleteLabel: 'Substance active',
    },
    save: 'Ajouter à la commande',
    quantityLabel: 'Combien de boîtes avez vous besoin ?',
    quantityHygprodLabel: 'Combien de {quantityType} avez-vous besoin?',
    concentrationLabel: 'Concentration',
    concentrationPlaceholder: 'π.χ 50, 100, 200',
    concentrationUnitLabel: 'Unité',
    concentrationUnitPlaceholder: 'π.χ ML, MG, G',
    hygProds: {
        byName: {
            title: 'Recherche du produit de santé',
            header: 'Tapez le nom du produit de santé ou de la sous-catégorie que vous voulez et sélectionnez-le dans la liste.',
            autocompleteLabel: 'Nom du produit de santé ou de la sous-catégorie',
        },
        byCategory: {
            title: 'Recherche de catégorie et de sous-catégorie',
            header: 'Tapez le nom de la catégorie ou de la sous-catégorie que vous voulez et sélectionnez-la dans la liste.',
            autocompleteLabel: 'Catégorie ou sous-catégorie du produit de santé',
        },
    },
  },
  needsBasket: {
    title: 'Produits à commander',
    completeOrder: 'Commande terminée',
  },
  needsPanel: {
    title: 'Ce que j\'ai demandé',
    emptyStatesMeds: 'Vous n\'avez enregistré aucun besoin de médicaments. Entrez votre besoin et voyez la disponibilité!',
    emptyStatesHygProds: 'Vous n\'avez pas déclaré avoir besoin d\'un produit de santé. Enregistrez votre besoin et vérifiez la disponibilité!',
    actions: {
      moreInfo: 'Ιnformations détaillées',
      gotMed: 'Je l\'ai reçu',
      edit: 'Editer',
      delete: 'Effacement',
      ok: 'Ok',
    },
    satisfyModal: {
      quantityText: 'Quantité assurée',
      successfulSatisfaction: 'La quantité demandée de besoins a été assurée avec succès.',
    },
    updateModal: {
      quantityText: 'Combien de boîtes vous faut-il?',
      successfulUpdate: 'La quantité de besoins a été mise à jour avec succès.',
    },
    deleteModal: {
      areYouSure: 'Êtes-vous sûr de vouloir supprimer ce produit de votre liste de besoins?',
      successfulDeletion: 'Le besoin a été supprimé avec succès.',
    },
  },
  automatch: {
    title: 'Résultats de recherche',
    infoHeaders: [
      'Vérifiez la disponibilité des médicaments et des produits de santé dont vous avez besoin.',
      'Vérifiez que vous disposez des documents nécessaires et recevez les médicaments et les produits de santé de l’organisme d’intérêt public de votre choix.',
    ],
    noAutomatchMeds: 'Il n\'y a pas de médicaments disponibles à réserver, en fonction des besoins indiqués sur la page "Ce que j\'ai demandé".',
    noAutomatchHygProds: 'Il n\'y a pas de produits de santé disponibles à réserver, en fonction des besoins indiqués sur la page "Ce que j\'ai demandé".',
    emptyStatesNoNeeds: 'Enregistrez vos besoins en médicaments et en produits de santé et vous trouverez ici les organismes d’intérêt public auprès desquels vous pouvez les obtenir.',
    emptyStatesNoAutomatch: 'Actuellement, les médicaments et les produits de santé que vous recherchez ne sont pas disponibles. Essayez à nouveau dans quelques jours.',
    emptyStatesNoAutomatchOrNoNeeds: 'Vous trouverez ici la disponibilité des médicaments et des produits de santé en fonction des besoins que vous avez déclarés. Si vous avez saisi vos besoins et qu\'aucun résultat n\'a été fourni, essayez à nouveau dans quelques jours.',
    map: 'Map',
    donationConditions: {
      title: 'Documents justificatifs',
      modal: {
        refugeeNote: 'Si vous êtes un réfugié et que vous vous êtes inscrit auprès d\'une ONG, demandez-leur de vous aider à contacter l\'organisme d’intérêt public.',
        title: 'Documents d\'accompagnement dont {organization} a besoin pour vous fournir des médicaments et des produits de santé.',
      },
    },
    reserveProduct: {
        button: 'RÉSERVATION',
        success: 'La pharmacie sociale {orgName} a été mise à jour (voir votre courriel). La réception de produits doit être effectuée dans un délai maximum de trois jours ouvrables. Avoir les documents nécessaires avec vous.',
    },
  },
  satisfiedNeeds: {
    title: 'Ce que j\'ai reçu',
    emptyStatesMeds: 'Vous n\'avez pas déclaré avoir reçu de médicaments à ce jour. Aller sur la page "Ce que j\'ai reçu" et indiquez si vous avez reçu une boîte!',
    emptyStatesHygProds: 'Vous n\'avez pas déclaré avoir reçu de produit de santé jusqu\'à présent. Allez à la page "Ce que j\'ai demandé" et déclarez si vous avez reçu une boîte!',
  },
  map: {
    title: 'Liste de pharmacies en Grèce',
  },
  faq: {
    title: 'Questions fréquentes',
    content: [
      {
        question: 'Comment puis-je contacter quelqu\'un pour des questions sur MEDforU?',
        answer: `<p>Envoyez un e-mail à hello@givmed.org pour contacter GIVMED, l'organisation à but non lucratif qui a créé MEDforU.</p>
            <p>Un membre de notre équipe vous répondra dans les plus brefs délais.</p>
            <p>Heures de contact: du lundi au vendredi, de 10h00 à 17h00.</p>
            <p>Langues parlées: grec et anglais.</p>
        `,
      },
      {
        question: 'Où vais-je venir chercher le médicament',
        answer: `<p> Les médicaments sont donnés par les les organismes d’intérêt public. Vous pouvez trouver leur adresse via l'application "MEDforU" sur la page "Résultats de la recherche".</p>
            <p>GIVMED, l'association à but non lucratif créée par MEDforU, ne stocke ni ne distribue de médicaments.</p>
        `,
      },
      {
        question: 'Je dois être enregistré auprès d\'une ONG pour obtenir le médicament ?',
        answer: `<p>Idéalement, la coordination de la collecte de vos médicaments auprès d'un organisme d’intérêt public où vous avez découvert qu'ils sont disponibles auprès de MEDforU devrait être assurée par une ONG.</p>
            <p>Si vous n'êtes pas enregistré, contactez-nous soit pour trouver une ONG, soit pour contacter l'organisme d’intérêt public.</p>
            <p>Dans tous les cas, vous devez avoir une prescription médicale.</p>
        `,
      },
      {
        question: 'J\'ai besoin d\'une prescription médicale ?',
        answer: `<p> Une ordonnance du médecin est nécessaire si vous avez besoin de médicaments sur ordonnance.</p>
            <p>Si vous avez besoin de médicaments en vente libre, la prescription n'est pas nécessaire.</p>
        `,
      },
    ],
  },
  tutorial: {
    title: 'Manuel d\'utilisation',
    mainTitle: 'Do you want to learn step by step how to use our MEDforU app? Watch the video below!',
    iframe: {
        title: 'MEDforU tutorial video',
    },
  },
  terms: {
    title: 'Conditions d\'utilisation',
    content: [
      {
        header: 'Conditions Générales d\'Utilisation GIVMED',
        text:
          'La société GIVMED Share medicine share life (ci-après dénommée la « Société ») est active dans l\'exploitation de médicaments non utilisés au profit de groupes socialement vulnérables. La société a créé et gère l’application MEDforU pour aider les personnes appartenant à des groupes socialement vulnérables à accéder aux médicaments dont elles ont besoin. En utilisant l’application MEDforU, vous acceptez sans réserve les conditions suivantes qui régissent son utilisation. Étant donné que pour utiliser cette application vous devez accepter ces conditions d\'utilisation, veuillez les lire attentivement. ',
      },
      {
        header: 'Propriété Intellectuelle',
        text:
          'Cette application, y compris les informations, marques commerciales ou logos de la société, est la propriété intellectuelle de la société qui est protégée par les lois nationales et européennes sur la propriété intellectuelle en vigueur (Loi 2121/1993, 2000/31/CE et 2001/29/CE.',
      },
      {
        header: 'Protection des Données à Caractère Personnel',
        text:
          'La société s’est engagée à assurer un niveau élevé de protection des données à caractère personnel de l’utilisateur, conformément aux exigences de la Loi n ° 4624/2019, du Règlement de l’Union Européenne   679/2019 sur la protection des données à caractère personnel et des dispositions applicables. La société a désigné Maitre Helene Dede (Avocate, Immatriculée au Barreau d’Athènes sous le numéro 34461), Chargée de la protection des données à caractère personnel pour tout problème lié à la protection des données à caractère personnel des utilisateurs de l\'application, conformément au Règlement 679/2019 de l\'Union Européenne sur la Protection des Données à Caractère Personnel.',
      },
      {
        header:
          'Collecte de données personnelles lors de l\'inscription à l\'application',
        text:
          'La société recueille les données personnelles de l\'utilisateur avec le consentement exprès de celui-ci lors de l\'enregistrement qui a lieu après le téléchargement de l\'application. L\'utilisateur accède au contenu de ces conditions d\'utilisation avant de s\'inscrire avec l\'application. En acceptant explicitement les conditions d\'utilisation et en exprimant le consentement explicite de l\'utilisateur au recueil de ses données personnelles, l\'application procède à la collecte des données personnelles de l\'utilisateur requises pour une utilisation correcte de l\'application. La collecte des données indiquées ci-dessous est une condition préalable à l\'utilisation de l\'application pour permettre aux utilisateurs de bénéficier du don de médicaments provenant de pharmacies sociales. Les données de l\'utilisateur sont celles indiquées par l\'utilisateur lui-même (le sujet) et avec son consentement explicite lors de son inscription à l\'application, comme indiqué ci-dessous.',
      },
      {
        header: 'Données personnelles que nous collectons',
        text:
          'Lors de son inscription, l\'utilisateur enregistre les données suivantes : e-mail, sexe, année de naissance, région où vous allez chercher les médicaments proposés  médicaments proposés. L\'utilisateur enregistre ensuite à l\'application les médicaments dont il a besoin et reçoit des informations sur leur disponibilité chez les entités d’utilité publique qui sont partenaires de la société. L\'application stocke les besoins en médicaments que l\'utilisateur a entrés dans son profil afin de les relier avec succès aux entités d’utilité publique qui peuvent les lui donner. La société ne collecte que les données de l’utilisateur qui sont expressément décrites ci-dessus.',
      },
      {
        header: 'Registre de données personnelles des utilisateurs',
        text:
          'Plus précisément, la société tient un registre des données personnelles susmentionnées de l’utilisateur conformément aux exigences de la Loi 4624/2019 sur la protection des données à caractère personnel et du Règlement No 679/2016 de l’Union Européenne sur la protection des données à caractère personnel. Pour tenir ce registre, la société a communiqué des informations à l’Autorité de protection des données à caractère personnel et doit se conformer à toute mesure légale et technique relative à la sécurité et à la protection de ces données. En outre, la société est titulaire d’une licence lui permettant de tenir un registre des données à caractère personnel sensibles qui a été émise par l’Autorité de protection des données à caractère personnel sous No. 1790/2017.',
      },
      {
        header: 'Droits de l’utilisateur relatifs à ses données à caractère personnel',
        text:
          'À tout moment l\'utilisateur a le droit de corriger, modifier, mettre à jour les données personnelles enregistrées à l’application, ou de demander leur suppression complète et permanente en remplissant le « formulaire de contact pour les données à caractère personnel des utilisateurs ». Pour toute question, demande, problèmes relatifs au recueil, le traitement et la conservation des données personnelles, les utilisateurs peuvent contacter la personne chargée de la protection des données à caractère personnel à l\'adresse e-mail <a class=\'default-link\' href=\'mailto:dpo@givmed.org\'>dpo@givmed.org</a>. En cas de violation du logiciel de sécurité, la société informe les utilisateurs en envoyant à chacun un message personnel dans les 24 heures suivant la notification de l’incident de sécurité pour leur fournir information détaillée  sur les données personnelles qui ont été enfreintes, des actions entreprises et des mesures techniques prises.',
      },
      {
        header: 'Utilisation de données à caractère personnel par des tiers',
        text:
          'Les données personnelles que l\'utilisateur déclare à l\'enregistrement et ensuite pendant l\'utilisation de l\'application GIVMED sont destinées exclusivement à garantir les services fournis, c\'est-à-dire, à coordonner le don de médicaments excédentaires à l\'utilisateur. Ils ne sont pas divulgués à des tiers inconnus à l\'utilisateur sans son consentement explicite. Nous notons que nous pouvons partager les données des utilisateurs avec nos affiliés afin de servir les objectifs de l\'application, sous condition de l’autorisation explicite de l’utilisateur donnée par le biais de l\'application. A la fin de son inscription à l’application et après avoir accepté les présentes conditions d\'utilisation, l’utilisateur donne son consentement explicite, spécifique et libre pour le recueil des données personnelles susmentionnées et leur conservation aux fins de la présente application. En même temps, l’utilisateur conserve le droit d\'accès et d\'objection à leur traitement sur demande de correction, d\'utilisation temporaire, de verrouillage, de non-transfert ou d’effacement définitif et complet, conformément aux dispositions de la Loi 4624/2019 et du Règlement Européen 679/2016 sur la protection des données personnelles.',
      },
      {
        header: 'Accès et dispositifs de réseau',
        text:
          'L\'utilisateur est responsable pour accéder au réseau de données, qui sont nécessaires à l\'utilisation de l\'application. Vous êtes responsable de l’obtention et de la mise à niveau /mise à jour du matériel compatible ou des périphériques nécessaires pour accéder et utiliser les services et applications et pour toute mise à niveau / mise à jour.',
      },
      {
        header: 'Information',
        text:
          'La société peut vous informer pour l’application par un avis général envoyé par e-mail à votre adresse. Vous pouvez contacter la société par communication écrite à l\'adresse : rue Victor Hugo 15, Metaxourgio, Athènes, Grèce ou par e-mail à <a class=\'default-link\' href=\'mailto:hello@givmed.org\'>hello@givmed.org</a>.',
      },
      {
        header: 'Limitation de la responsabilité',
        text:
          'La société vise à coordonner à travers la présente application le processus de don de médicaments entre l\'utilisateur et les entités d’utilité publique et ne porte aucune responsabilité pour les dons de médicaments qui auront lieu par l\'utilisation de l\'application. En outre, l\'entreprise n\'est pas responsable de l\'exactitude des données et des informations saisies par l\'utilisateur, qui est seul responsable de leur véracité. La société ne fournit aucune garantie quant à la qualité des médicaments donnés alors qu\'elle n\'assume aucune responsabilité en cas de dommages causés par un tiers à l\'utilisation éventuelle d\'un médicament. Enfin, la société ne sera en aucun cas responsable de toute réclamation de nature juridique ou civile et/ou criminelle ou de tout dommage causé par les utilisateurs de l’application ou des tiers dans le cadre de son exploitation ou utilisation et du processus de don de médicaments par son intermédiaire.',
      },
      {
        header: 'Conditions d’utilisation',
        text:
          'Cette application ne peut pas être utilisée par des personnes qui n\'ont pas atteint l\'âge de 18 ans. Il est expressément indiqué que la société n\'assume aucune responsabilité en ce qui concerne l\'exactitude de la date de naissance enregistrée par l\'utilisateur et par conséquent elle n\'est pas responsable dans le cas de l\'utilisation de l’application par des personnes qui n\'ont pas atteint l\'âge de 18 ans.',
      },
      {
        header: 'Jurisdiction',
        text:
          'L\'utilisateur accepte expressément que tout différend découlant avec le GIVMED est soumis à la juridiction et  la compétence exclusive les tribunaux d\'Athènes et que le droit applicable est la loi grecque.',
      },
    ],
  },
  contact: {
    title: 'Nous contacter',
  },
  about: {
    title: 'Renseignez-vous sur les médicaments',
    content: [
        {
          question: 'What exactly is medicine?',
          answer: `<p>Medicine is substances, chemical or herbal, that have been scientifically proven to be effective and safe, and are taken or given to the body with the aim of:</p>
              <ul>
              <li>
                  The treatment of a disease or a pathological condition (e.g., antibiotics are taken to treat a bacterial infection).
              </li>
              <li>
                  Treating or relieving the symptoms of a chronic or non-chronic illness (e.g., asthma medications, painkillers, etc.)
              </li>
              <li>
                  Prevention of a disease (e.g., the flu vaccine).
              </li>
              <li>
                  Reducing the progression of a disease (e.g., drugs for dementia).
              </li>
              <li>
                  The diagnosis of a pathological condition (e.g., contrast tomography).
              </li>
              </ul><p>`,
        },
        {
          question: 'Prescription and non-prescription medicines',
          answer: `<p><b>Prescription medicines (SY.FA.)</b> are those that in order to buy you need to have a prescription from a doctor [1]</small>.</p>
              <b>Non-prescription drugs (NSAIDs)</b> are those that you can buy without the need for a doctor's prescription, are usually taken for a short period of time, and are mainly for mild conditions that you can deal with on your own (e.g., x. headache pills).
              <br><br> <p><small>[1] For the possibility of a free prescription, you can contact your Municipality to inform you if it is done in the Municipal Clinics and if it concerns only specific groups of the population or all citizens.</small></p>`,
        },
        {
          question: 'Original and generic medicines',
          answer: `<p><b>An original medicine</b> is one that is produced and sold by the pharmaceutical company that created it from the beginning. When a pharmaceutical company discovers a new medicine, it has for some years the exclusive rights to produce and sell it ("patent"), ie no other pharmaceutical company can manufacture and sell a corresponding medicine with the same active ingredient (the component of the medicine due to its action in the body (see "What is the active substance"). After some years (20-25) the patent expires, thus allowing other pharmaceutical companies to develop and sell medicines containing the same active substance as the original medicinal product and which are called generics.</p>
              <b>Generic medicines</b> meet the same strict quality, safety, and efficiency standards as the originals. In this case, many medicines with different brand names may be released, but the active substance they contain is the same (even the form and dosage). Generic medicines may differ from the original medicines in form, size, shape, color, packaging, and inactive components (called excipients), ie those components that do not contribute to the action of the medicine. However, they are used to treat the same conditions as the originals and are just as effective. Generic medicines cost less than the original ones because, obviously, the pharmaceutical companies that sell them have not spent money on their research and development. The decision about which medicine to take and whether it is original or generic should always be made under the guidance of your doctor or pharmacist.`,
          },
        {
          question: 'What is the active substance of a medicine?',
          answer: 'The active substance of medicine is the ingredient that allows it to have a therapeutic effect on the human body, that is, makes it effective in the treatment of the disease or condition for which we take it, or to have a medicinal effect, such as the medicines we take. to do a diagnostic test (eg the contrast medicine given before the CT scan). The dosage recommended by your doctor or pharmacist for each medicine is essentially the amount of active substance you need to take to be able to have the desired effect on improving your health. The warnings that exist, also, for possible side effects in all medicines, are those that can in some cases be caused by the active substance of each medicine. ',
        },
        {
          question: 'What are antibiotics and what is their correct intake?',
          answer: `<p><b>What are antibiotics?</b></p><p>Germs include bacteria, viruses, fungi, and parasites. Antibiotics have been developed to treat infections caused exclusively by bacteria by killing them or stopping them from growing. Antibiotics do not work against all bacteria, which means that not all of them are suitable for treating any type of bacterial infection. Some aim to treat a single class of bacteria, while others aim to treat more than one. However, antibiotics are by no means effective against infections caused by other types of germs, such as viruses (such as the flu or the common cold).</p>
          <p><b>What is antibiotic resistance (or antimicrobial resistance)?</b></p><p>Antibiotic resistance is not related to the human body, that is, our body does not become resistant to antibiotics. It means that the bacteria develop the ability to overcome the medicines that are designed to treat them, so they are not killed and continue to grow despite taking them. Consequently, the bacterial infection in our body is not cured. These bacteria were previously sensitive to the antibiotics that were created to fight them, but they eventually managed to genetically alter (mutate) and develop resistance to them.</p>
          <p><b>Why does antibiotic resistance occur?</b></p><p>Developing resistance of bacteria to certain antibiotics is a natural development that can occur. However, when antibiotics are taken unnecessarily, excessively, and inappropriately, this process becomes much faster and to a greater extent. That is, bacteria are created for which there are no antibiotics available that can treat them. This means that while in the past some infections were treated with certain antibiotics, now they are becoming dangerous to humans again, as the antibiotics that were available to treat them are no longer effective.</p>
          <b>What to do & not to do about antibiotics</b></p><p>The responsible and correct intake of antibiotics helps to reduce the phenomenon of resistance so that these medicines remain effective in the treatment of bacterial infections. That is why it is very important to follow the following basic instructions:</p>
          <p><ul>
              <li>
                  <b>Only take antibiotics when recommended by your doctor. Antibiotics can only be obtained with a prescription.</b>
              </li>
              <li>
                  Follow your doctor's instructions exactly for the type of antibiotic you will be taking, the dosage, and the duration you will be taking it.
              </li>
              <li>
                  Common infections caused by viruses (not bacteria), such as whooping cough, cough, sore throat, bronchitis, otitis, sinusitis, etc. They are NOT treated with antibiotics.
              </li>
              <li>
                  Never use antibiotics that you may have left at home from previous treatment or on the advice of relatives and friends. Only a doctor can decide if you should take an antibiotic. Taking antibiotics when you should not do more harm to your health than good.
              </li>
          </ul>,<p>`,
        },
        {
          question: 'What are medication compliance, drug overdose, sub-therapeutic dose, and multi pharmacies?',
          answer: `<p><b>Compliance with the medication:</b> To faithfully follow the medication prescribed by the doctor and the relevant instructions for taking it, even without the close supervision of the doctor.</p>
              <p><b>Medicine overdose or abuse:</b> Take a higher dose of medicine than your doctor prescribes and it is recommended that you take it. This can be done by mistake, for example in case someone forgets that he took the dose of the medicine and takes a second one.</p>
              <p><b>Hyper therapeutic dose:</b> Take less medicine than your doctor prescribes and it is recommended to take or skip doses.</p>
              <p><b>Multitherapy:</b> Taking multiple medications at the same time, prescription or not. It can lead to mistakes in taking and dosing and to harmful interactions between them.</p>`,
        },
        {
          question: 'What are the side effects or bad side effects of medicine?',
          answer: `<p>They are unwanted or unexpected symptoms that can be observed after using a medicine. These side effects can be mild or even severe to the point that they can be life-threatening (eg dizziness, nausea, confusion, headache, dry mouth, shortness of breath, rash, allergic reaction, damage to organs of the body, etc.). These include those known to be caused by the use of a medicament e.g., according to the approved indications (and therefore mentioned in the package leaflet of the medicine), and those caused by other events, such as overdose, misuse, abuse, etc. If you suspect that a medicine may have caused you some side effects contact your doctor or pharmacist directly for information.</p>
          <p><b>CAUTION: Do not stop your medication unless your doctor tells you to.</b></p>
          <p>It does not necessarily mean that the medicine or the active substance of the medicine causes the side effects or that the medicine cannot be used safely. Whether or not you will continue your medication and how this will be done will only be decided by your doctor. The National Medicines Agency (EOF) has created the "Yellow Card" as a means of gathering information on the side effects of drugs. The "Yellow Card" is accessible via the electronic link https://www.eof.gr/web/guest/yellowgeneral. If you want to alternatively fill in the "Yellow Card" in printed form, you can contact the Department of Side Effects of EOF (284 Mesogeion, 155 62, Athens or the contact numbers 213 2040380 and 213 2040337, Fax 210 6549585) or directly to your pharmacy. The "Yellow Card" can be completed by both health professionals and patients themselves. So do not hesitate to mention side effects that are unknown to you and are not listed in the package leaflet of this medicine or if you have any doubts about whether they are related to any medicine you are taking. The information you provide can be very important to make known a side effect of medicine so that steps can be taken to ensure that patients take it more safely and safely. If you have difficulty filling out the "Yellow Card" yourself, ask your doctor or pharmacist for guidance.</p>`,
        },
        {
          question: 'How can medicine provoke interactions?',
          answer: `<p><b>With other medicines</b></p><p>It occurs when two or more medicines react with each other and cause unexpected actions. If you are taking different medicines and / or you are being watched by more than one doctor, you should know both you and all of your medication that you are taking to avoid any interactions between medicines.</p>
          <p><b>With food / beverages</b></p><p>Food can affect how the body absorbs a medicine. Also, concomitant intake of alcohol and certain medicines can reduce their effectiveness and / or cause side effects such as fatigue and reduction in the speed of reflexes. So it is important to follow closely the instructions for taking a medicine you will receive from your doctor or pharmacist.</p>
          <p><b>With existing diseases</b><p><p>The existence of a disease may not allow certain medicinal products to be taken, as it can make it dangerous for your health. This means that your doctor is important to know, except all your medication, and your whole medical history.</p>`,
        },
        {
          question: 'How can I get my medicine right?',
          answer: `<p>Some medications will need to be taken for a period of time (until you are actually cured of the condition you are taking them for), while in other cases you may need to take them for the rest of your life (as is often the case with chronic illnesses, e.g..x. diabetes). Therefore, proper medication intake is very important for your health. If you do not follow your doctor or pharmacist's instructions on how to take a medicine, it may reduce its effectiveness and ultimately not help you or end up hurting your health.</p>
          <p>That is why it is very important to:</p>
          <p><ul>
            <li>
              take exactly the dose recommended by your doctor or pharmacist.
            </li>
            <li>
              take the medicine at the hours and days recommended by your doctor or pharmacist.
            </li>
            <li>
              take the treatment for the period of time recommended by your doctor or pharmacist (no more, no less)
            </li>
            <li>
              follow the instructions for taking the medicine given to you by your doctor or pharmacist as to whether or not you should take this medicine with or without food and if you should avoid taking certain foods or drinks for as long as you take it.
            </li>
            <li>
              do not take expired medicines.
            </li>
            <li>
              keep in mind that some medicines, if you open the package (eg eye drops), then expire earlier than the expiry date stated on the package (in which case the expiry date after opening is indicated on the package leaflet medicine).
            </li>
            <li>
              talk to your doctor or pharmacist for guidance on what to do if you miss a dose, do not decide for yourself.
            </li>
            <li>
              do not crush tablets to make powder or open capsules to make them easier to swallow unless your doctor or pharmacist tells you that this is safe for this medicine.
            </li>
            <li>
              do not ignore an unexpected side effect that you think may be related to a medicine you are taking, contact your doctor or pharmacist directly.
            </li>
          </ul></p>
          <p>It is also important not to give friends and relatives medicines prescribed by your doctor for you, as you may not know exactly what they are suffering from and whether the medicine you are taking will help or harm them. Only the doctor who examines them can decide which medicine they should take, what is the right dose, and for how long they should take it. Similarly, do not use medicines that have been prescribed for your relatives or friends. For more information see the section "What can you do with expired medications but no longer need them".</p>`,
        },
        {
          question: 'How to organize the medication I am taking?',
          answer: `
          <ul>
            <li>
              Use a medication adjustment box for the whole week, which will help you organize the medications you take on a daily basis. This box can be obtained from any pharmacy.
            </li>
            <li>
              If the time your doctor or pharmacist tells you to take a medicine coincides with another time, combine the two actions (eg take the medicine as soon as you brush your teeth every morning).
            </li>
            <li>
              Stick notes with reminders to take the medicine in places you see all the time, such as the bathroom mirror or the refrigerator door.
            </li>
            <li>
              Use a diary to keep track of each time you take your medicine and the dose you take.
            </li>
            <li>
              Make reminders on your cell phone to let you know the days and times you need to take your medicine.
            </li>
            <li>
              If possible, ask a family member or friend to help you, reminding you when you need to take your medicine.
            </li>
            <li>
              Talk to your doctor or pharmacist for guidance on what to do if you miss a dose, do not decide for yourself.
            </li>
            <li>
              Do not crush tablets to make powder or open capsules to make them easier to swallow unless your doctor or pharmacist tells you that this is safe for this medicine.
            </li>
            <li>
              Do not ignore an unexpected side effect that you think may be related to a medicine you are taking, contact your doctor or pharmacist directly.
            </li>
          </ul>
          `,
        },
        {
          question: 'What do I do before I start taking medicine?',
          answer: `<p>Inform your doctor or pharmacist:</p>
          <p><ul>
            <li>
              For any other medicines, you are taking, even if they are herbal, vitamins, or dietary supplements. Ask if you can take them with your new medicine or if you need a change. You will thus be able to avoid any interactions between the various medications that may be detrimental to your health.
            </li>
            <li>
              If you have a proven allergy to certain medications.
            </li>
            <li>
              If you have a surgery scheduled.
            </li>
            <li>
              If you are pregnant or breastfeeding.
            </li>
            <li>
            If there is something you do not understand about the purpose, method of administration, dosage, or any other relevant information about the medicine.
            </li>
          </ul></p>
          <p>To have a good picture of all the medicines you use, but also to be able to inform your doctor or pharmacist at any time, it is very useful to make a list of all the medicines you are taking. A table, ie for each medicine that could have columns:</p>
          <ol>
            <li>
              Drug Name
            </li>
            <li>
              Why am I taking the medicine?
            </li>
            <li>
              Form (eg pill, drops, syrup, patch, injection, etc.).
            </li>
            <li>
              Dose (eg 1mg, 20ml, etc.).
            </li>
            <li>
              How often should I take it & how many doses.
            </li>
            <li>
              Date I started taking it.
            </li>
            <li>
              Date I should stop taking it (if I have a doctor or pharmacist).
            </li>
            <li>
              Other instructions on how to take it (eg with food or fasting / or should not drink alcohol, etc.).
            </li>
          </ol>`,
        },
        {
          question: 'What should I ask my doctor or pharmacist before I start taking medicine?',
          answer:
          `<ul>
              <li>
                  What does this medicine do?
              </li>
              <li>
                  What will it help me with?
              </li>
              <li>
                  Why should I take it?
              </li>
              <li>
                  Does it replace any of the medications I am already taking?
              </li>
              <li>
                  When and how should I take it? How much will I get each time?
              </li>
              <li>
                  How long will it take me to get it?
              </li>
              <li>
                  Can I chew, crush or melt this medicine to get it?
              </li>
              <li>
                  When will I start to see some improvement? How do I know I'm getting better?
              </li>
              <li>
                  Do I need to do any tests to see if the medicine really helps me? If so, what are these tests and when should I take them?
              </li>
              <li>
                  What should I do if I miss a dose?
              </li>
              <li>
                  What should I do if I accidentally take more medicine than I should?
              </li>
              <li>
                  Are there any possible side effects of taking this medicine?
              </li>
              <li>
                  Do I need to avoid certain medications or supplements for as long as I take this medication?
              </li>
              <li>
                  Do I need to avoid certain foods, drinks, or activities (eg driving) for as long as I take this medicine?
              </li>
              <li>
                  What is the best way to store this medicine?
              </li>
              <li>
                  Is there a case for me to develop tolerance or dependence on this particular drug?
              </li>
              <li>
                  Does my insurance cover this medicine?
              </li>
          </ul>`,
        },
        {
          question: 'How do I store my medicines?',
          answer: `<p>Each medicine is different, so always ask your doctor or pharmacist how to store it and read the package leaflet (FOX) carefully.</p>
          <ul>
            <li>
              As a general guideline, store medicines in a cool, dry place out of direct sunlight, as they can be harmful. That's why it's not good to keep medicines in the bathroom, kitchen, or car, where it is hot and humid.
            </li>
            <li>
              Some medicines may need to be stored in the refrigerator. Especially insulin, vaccines, and some eye drops and syrups require specific temperature conditions not only when stored but also when transported from the pharmacy to your home. Therefore, for these classes of medicines, follow faithfully and with special care the instructions that the pharmacist will give you both for their transport and their storage.
            </li>
            <li>
              Always keep the medicines in their original packaging, because it has the expiration date of the medicine on it and the package leaflet inside.
            </li>
            <li>
              Do not put different medicines in the same container.
            </li>
            <li>
            <p>Do not use pills or tablets if they are damaged, ie:</p>
              <ol>
                <li>
                  are fragile or have disintegrated.
                </li>
                <li>
                  have discolored.
                </li>
                <li>
                  are soft (while not normally so).
                </li>
                <li>
                  creams that have changed their texture, color, and/or smell bad.
                </li>
                <li>
                  Keep medicines in a safe place out of the reach and sight of children (see Parenting Tips).
                </li>
            </li>

          `,
        },
        {
          question: 'What should parents be aware of about their medications and children?',
          answer: `<p><b>Put the medicines and vitamins you have at home in a safe place that children do not see and cannot reach.</b></p><p>Find such a place in your home and store there all the medicines and vitamins you have, even the ones you use daily. Do not scatter them in different parts of the house and do not leave them in common view and in places that could be reached by small children. The medicines we leave on benches, tables, nightstands, bags, or wallets can be easily accessed by young children, who have an innate curiosity about everything around them and put all sorts of things in their mouths.</p>
          <p><b>If there is a safety cap on the package, make sure you always close it properly after each use.</b></p><p>If there is a safety cap on the medicine package, turn it over until you hear a "click" feature. Even so, this does not mean that children could not open the package. So again even these medicines should be kept together with all the rest away from children, as mentioned above, and never given to them to keep as a toy even if it is for a while.</p>
          <p><b>Pay special attention to the dosages of the medicines you give to the children.</b></p><p>You are well aware of the dosage of the medicine you are going to give your child. Follow the instructions of your pediatrician or pharmacist carefully and read the package leaflet carefully. If the medicine is in liquid form, there is usually a dose-measuring instrument (teaspoon, cup, or syringe) already in the package. If not, ask your pharmacist to give you one that is right for you. Do not use spoons, as they come in different sizes and are not suitable for this purpose, so you may end up giving your child a smaller or larger dose of medicine than they should. If the child is unable to take the full dose at some point (because he is crying, spitting on some of it, vomiting after a while, etc.), do not give him a second dose directly even if you have doubts about whether managed to get the dose he needed. Talk to your pediatrician to guide you on what is best to do in each case.</p>
          <p><b>Discuss with your children about medicines and how they are used safely</b></p><p>Discuss with your children and explain calmly and logically what the medicine is, why we take it and why they should only take it when given to them by an adult in the family. Do not tell your children that medicines are candies to persuade them to take them. Many drugs are brightly colored and may look like candy to their eyes, making the test even more tempting. Teach older siblings that they should never give medication to younger siblings before first talking to an adult in the family.</p>
          <p><b>Ask guests and guests to follow the same rules.</b></p><p>Remind guests and guests who have medicines with them that they should also keep them, while they are at home, in a safe place that small children do not see or reach.<p>
          <p><b>Be prepared in case of an emergency.</b></p><p>Always have the emergency number (166) and the Poison Center (2107793777) available and call immediately if you think your child may have taken any medicine or vitamins on their own.</p>`,
        },
        {
          question: 'What should people taking care of other people`s medicines watch out for?',
          answer: `<p><b>Get to know all the medication he is taking and plan how he will take it.</b></p><p>Make a list of all prescription and over-the-counter medications, including herbal remedies and supplements. If for some reason you do not take a dose of the drug, do not give a double later or try to make up for it in some way. Talk to your doctor or pharmacist for advice on what to do in each case depending on your medication. Use a medication adjustment box for the whole week, which will help you organize daily the medications that the person you care for needs to take. You can also use a diary to more easily remember when to take each medication and dosages, as well as important appointments with the attending physicians. Medications should also be given at certain times of the day and in some cases should be combined with food, while others should not. If you do not follow the exact instructions of your doctor or pharmacist to take the medicine correctly, its therapeutic effects may be reduced.</p>
          <p><b>Seek regular communication and cooperation with your doctor and pharmacist.</b></p><p>For any new medicine, you need to take, first ask your doctor or pharmacist if it is safe in combination with all the other medicines he is taking. Important questions to ask your doctor or pharmacist about any new medicine you are about to take are:</p>
          <p><ul>
            <li>
              What is the purpose of this medicine?
            </li>
            <li>
              What improvement in the health of the person I take care to expect and in how long will it happen?
            </li>
            <li>
              When and how should he take this medicine, what is the dose, and for how long will he take it?
            </li>
            <li>
              What do I do if I miss one or more doses?
            </li>
            <li>
              Can he take this medicine in combination with the rest he is taking?
            </li>
            <li>
              Are there any possible side effects that may occur and what are they?
            </li>
            Make sure you keep in touch with the doctors who are watching him/her and arrange the appointments he/she needs with them so that they can review the medication at regular intervals and adjust it if necessary. For long-term medication, be sure to renew your prescriptions in time before your home medication is completely exhausted so that you do not miss any of the doses. At your doctor's appointment, discuss whether the person you are caring for is improving and if he or she has had side effects or new symptoms that he or she has not had before.
          </ul></p>
          <p><b>ΤWhat to do if he refuses to take the medicine.</b><p><p>If the person you take care, refuses to take his medication, try to understand exactly where the problem lies. In other words, this medicine may cause some side effects that it wants to avoid or it may be difficult for you to swallow a large pill. Do not try to give him the medicine by force. Avoid crushing tablets to turn them into powder or opening capsules to mix them with a drink or food, as this may not be safe. Consult your doctor or pharmacist about what to do in these cases. For example, they may suggest another drug that is just as effective but is in liquid form, so that it is easier to swallow.</p>`,
        },
        {
          question: 'Where do I throw my medicines when they expire?',
          answer: `<p>Expired medicines should NOT be discarded:</p>
          <p><ul>
            <li>
              in the neighborhood rubbish bins
            </li>
            <li>
              in the sink
            </li>
            <li>
              in the toilet bowl,
            </li>
          </ul></p>
          <p>because the chemicals they contain pollute both the soil and the water in the environment, posing a significant risk to everyone's health. Expired medicines should only be thrown away in the green metal bins found in pharmacies. There they are temporarily collected and handed over to the Institute of Pharmaceutical Research and Technology (IFET) to be safely destroyed.</p>
          <p><b>What can you throw in the green bins of pharmacies?</b></p>
          <p><ul>
            <li>
              Pills and capsules in the foil containing them (paper and plastic packaging of medicines should be disposed of in recycling bins).
            </li>
            <li>
              Well-sealed glass bottles of syrups and vials for injection.
            </li>
            <li>
              Tubes with ointments and creams.
            </li>
          </ul></p>
          <p><b>What you should NOT throw in the green bins of pharmacies.</b></p>
          <ul>
            <li>
              Mercury (eg thermometers)
            </li>
            <li>
              Syringes
            </li>
            <li>
              Cracked injection vials
            </li>
            <li>
              Broken syrup bottles
            </li>
            <li>
              Gauze
            </li>
            <li>
              Cotton
            </li>
          </ul>`,
        },
        {
          question: 'What should I do with my surplus medications?',
          answer: `<p>If you have medicine that is about to expire or you have leftovers because your treatment is over, you can donate them to other people who need them, even if you have a small amount left.</p>
          <p>Το <b>GIVMED</b> is a non-profit organization that aims to give people immediate access to the medicines they need. You can easily and quickly donate the medicines you no longer need to social pharmacies, nursing homes, and other charities.</p>
          <p>At the <b>GIVMED</b> website <a href="https://givmed.org/" target="_blank">(https://givmed.org/)</a> you can find the charities that are near you and need the medicines you wish to donate. If you are unfamiliar with the technology, you can call GIVMED (210 3007222) for directions.</p>
          It is important that the medicine or medicines you wish to donate meet the following conditions:
          <p><ul>
            <li>
              The expiration date has not passed.
            </li>
            <li>
            It is not a medicine that needs a refrigerator.
            </li>
            <li>
            It is not open if it is a syrup, ointment, drops, or inhaled product.
            </li>
            <li>
              It does not belong to the category of digram drugs.
            </li>
            <li>
              It has been stored at a controlled room temperature of 25 degrees and away from any kind of heat or humidity source.
            </li>
            <li>
              The medicine voucher (i.e. the barcode sticker) has been removed.
            </li>
          </ul></p>
          <p><b>Do not forget that medicine is a good to which all people have the right to be able to take care of their health in the best possible way. If you have any further questions or concerns, do not hesitate to talk to your doctor and pharmacist, who are best placed to assist you and guide you through what you need to know about your medication and take care of your health.</b></p>`,
        },
      ],
  },
  logout: {
    title: 'Se déconnecter',
  },
  organizationIsClosed: 'Closed',
  weekdays: {
    monday: 'Lundi',
    tuesday: 'Mardi',
    wednesday: 'Mercredi',
    thursday: 'Jeudi',
    friday: 'Vendredi',
    saturday: 'Samedi',
    sunday: 'Dimanche',
  },
  quantityType: {
      BOX: 'Boîtes | Boîtes',
      BAG: 'Sacs | Sacs',
      PIECE: 'Pièces (sous-unité de la boîte) | Pièces (sous-unité de la boîte)',
      OTHER: 'Autre | Autre',
      ML: 'ml',
      pieces: 'Pièces | Pièces',
      subUnit: '(sous-unité de la boîte) | (sous-unité de la boîte)',
  },
  quantityTypePlural: {
      BOX: 'Boîtes',
      PIECE: 'Pièces (sous-unité de la boîte)',
      OTHER: 'Autre',
  },
  labels: {
    lang: 'Langue',
    address: 'Adresse',
    phone: 'Telephone',
    medName: 'Nom de médicament',
    medSubs: 'Substance active',
    hygProdName: 'Nom du produit de santé',
    hygProdCategoryOrSubCategory: 'Nom de la catégorie | nom de la sous-catégorie',
    subCategory: 'Sous-catégorie',
    expDate: 'Date d’expiration',
    quantity: 'Quantité',
    productUnit: 'Unité du produit',
    needDate: 'Date d\'enregistrement',
    concentration: 'Concentration',
    boxes: 'Boîtes | Boîtes',
    newSearch: 'Nouvelle recherche',
    newNeed: 'Ajouter un nouveau besoin',
    checkMore: 'Voir plus',
  },
  organizationDocuments: {
    annual_salaray_adjustment: 'Avis d\'impôt',
    proof_of_residence: 'Preuve de résidence (p. Ex. facture de téléphone, d\'électricité, contrat de location)',
    doctor_prescription: 'Prescription médicale',
    family_status_certificate: 'Fiche d\'état civil',
    vulnerability_certificate: 'Certificat de vulnérabilité (p. ex. taux d\'invalidité, certificat de famille nombreuse etc)',
    id_or_passport_copy: 'Copie de carte d\'identité ou de passeport',
    annual_tax_application: 'Déclaration de revenus (E1) ',
    unemployment_card: 'Carte de chômage',
    real_estate_declaration: 'Déclaration des biens immobiliers (E9)',
    insurance: 'Les patients sans sécurité sociale fournissent une déclaration attestant qu’ ils ne sont pas assurés. Les patients assurés fournissent une copie de la première page du carnet de santé',
    social_solidarity_income: 'Les patients qui sont bénéficiaires du Revenu de Solidarité Sociale (KEA), doivent présenter la carte d\'identité et la demande approuvée du Revenu de Solidarité Sociale (KEA)',
    application: 'Demande (fournie et complétée par la pharmacie sociale)',
    valid_residence_permit: 'Titre de séjour en vigueur (pour les étrangers)',
    municipality_certificate: 'Les citoyens qui ne résident pas à la municipalité doivent fournir une attestation de la municipalité de résidence qui certifie qu\'ils ne sont desservis par un programme pareil.',
    pension_scrap_or_salary_certificate: 'Confirmation de retraite ou attestation de salaire',
    photograph: 'Photo de passeport',
    birth_certificate: 'Certificat de naissance (pour les mineurs)',
    vat: 'Numéro d\'Identification Fiscale (AFM)',
  },
  newAppVersionAvailable: {
    text: 'Nouvelle version disponible. Cliquez pour mettre à jour',
    btn: 'Réactualiser',
  },
  offlineMode: {
    text: 'Vous êtes actuellement hors ligne',
  },
  iosInstallBanner: {
    text: 'Pour installer MEDforU à votre portable, clicker l’ icone "share" and apres choisissez "Add to homescreen".',
  },
  errorCodes: {
    serverConnectionProblem: 'Oups! Il semblerait qu\'il y ait un problème. Veuillez nous contacter.',
    generalValidationError: 'Données incorrectes.',
    multipleObjectsReturned: 'Plusieures lignes retournées.',
    accountDisabled: 'Compte désactivé.',
    userAlreadyExists: 'Cet e-mail est déjà utilisé par une autre personne.',
    wrongCredentials: 'ID d’utilisateur ou mot de passe incorrect.',
    validationErrorException: 'Oups !!! Problème.',
    invalidToken: 'Invalid token',
    wrongCurrentPassword: 'Mot de passe incorrect.',
    authenticationFailed: 'Authentification échouée.',
    coveredQuantity: 'Quantité couverte supérieure à la quantité nécessaire.',
    sendEmailException: 'Une erreur est survenue en essayant d\'envoyer un courrier électronique à l\'utilisateur.',
    transactionIntegrityError: 'Erreur d\'intégrité de la transaction.',
    productNotFound: 'This product is not founded.',
    productNotMatchedWithYourNeeds: 'This product is not matched with your needs.',
    requestedReservedQuantityGreaterThanPermitedAvailableQuantity: 'Requested reserved quantity of this product is greater than permitted available quantity.',
    noEmailSetForThisOrganization: 'There is not email set for this organization.',
  },
};
