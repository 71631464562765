export default {
    meds: 'MEDICINE',
    hygProds: 'HEALTH PRODUCTS',
  baseAuth: {
    later: 'Later',
  },
  preSignup: {
    title: 'In order to get medicine and health products, you need to create an account using an e-mail address. You will not need to provide any other information.',
    agree: 'OK',
  },
  signup: {
    title: 'Create an account',
    repeatPassword: 'Repeat password',
    submit: 'Create an account',
    agreeToTermsOfUse: 'By creating an account you agree to the',
    termsOfUse: 'Terms of use',
  },
  login: {
    title: 'Sign in',
    email: 'E-mail',
    password: 'Password',
  },
  forgotPassword: {
    title: 'Forgot your password?',
    text: 'Please enter your email address to search for your account. We will send you instructions to login.',
    cancel: 'Cancel',
    submit: 'Send',
    successText: 'We have sent the ‘password setup’ instructions to your email. If you have not received an email, please make sure that you have entered the same email address that you used for your registration, and check the spam folder.',
  },
  resetPassword: {
    title: 'Reset your password',
    password: 'Password',
    repeatPassword: 'Repeat password',
    submit: 'Confirm',
  },
  rules: {
    requiredField: 'This field is required',
    minLength: 'Type at least 3 characters',
    passwordLength: 'The minimum password length is 8 characters.',
    emailValidation: 'Please enter a valid email address.',
    passwordsNotMatch: 'The passwords don’t match.',
    quantity: 'Invalid quantity',
    invalidBirthYear: 'Invalid birth year',
  },
  profile: {
    title: 'User profile',
    email: 'Email',
    language: 'Language',
    appVersion: 'App version',
    regionalUnities: {
      title: 'The regional unities where you can pick up the medicines and health products.',
      helpText: 'Select the regional unities of your interest. If you do not choose any, the search will include all available regional unities.',
    },
    sex: 'Sex',
    sexTypes: {
        male: 'Man',
        female: 'Woman',
        nonBinary: 'Non-binary',
        noneOfTheAbove: 'None of the above',
        noAnswer: 'I prefer not to answer',
    },
    birthYear: 'Year of birth',
    changesSubmittedSuccessfully: 'Your changes were submitted successfully.',
    notificationsEnabled: 'Notifications are enabled.',
    notificationsDisabled: 'Notifications are disabled.',
    changePassword: {
      title: 'Change password',
      currentPassword: 'Active password',
      newPassword: 'New password',
      repeatPassword: 'Repeat password',
      submit: 'Submit',
      succesfulSubmittion: 'Your password was changed successfully.',
    },
  },
  addNeeds: {
    title: 'Register your needs',
    byName: {
      title: 'Search for medicine',
      header: 'Type the name of the medicine that you need and select it from the list.',
      autocompleteLabel: 'Name or active substance of the medicinal product',
    },
    bySubstance: {
      title: 'Search for active substance',
      header: 'Type the combination of active substances you want and select it from the list.',
      autocompleteLabel: 'Medicine substance',
    },
    save: 'Add a medicine need to your order',
    quantityLabel: 'How many boxes do you need?',
    quantityHygprodLabel: 'How many {quantityType} you need?',
    concentrationLabel: 'Concentration',
    concentrationPlaceholder: 'e.g. 50, 100, 200',
    concentrationUnitLabel: 'Unit',
    concentrationUnitPlaceholder: 'e.g. ML, MG, G',
    hygProds: {
        byName: {
            title: 'Search for health product',
            header: 'Type the name of the health product or the subcategory you want and select it from the list.',
            autocompleteLabel: 'Name of the health product or of the subcategory',
        },
        byCategory: {
            title: 'Search for category and subcategory',
            header: 'Type the name of the category or subcategory you want and select it from the list.',
            autocompleteLabel: 'Health product category or subcategory',
        },
    },
  },
  needsBasket: {
    title: 'Products to order',
    completeOrder: 'Order completed',
  },
  needsPanel: {
    title: 'What have I asked for',
    emptyStatesMeds: 'No medicine needs have been registered. Register your medicine needs and check the availability!',
    emptyStatesHygProds: 'You haven\'t registered a need for a health product. Register your need and check availability!',
    actions: {
      moreInfo: 'Detailed information',
      gotMed: 'Received',
      edit: 'Edit',
      delete: 'Delete',
      ok: 'OK',
    },
    satisfyModal: {
      quantityText: 'Quantity covered',
      successfulSatisfaction: 'The requested need quantity was successfully met.',
    },
    updateModal: {
      quantityText: 'How many boxes do you need?',
      successfulUpdate: 'Need quantity successfully updated.',
    },
    deleteModal: {
      areYouSure: 'Are you sure you want to delete this product from your needs’ list?',
      successfulDeletion: 'The need was successfully deleted.',
    },
  },
  automatch: {
    title: 'Search results',
    infoHeaders: [
      'Check the availability of the medicine and health products you need.',
      'Check that you have the necessary documentation and receive the medicine and health products from the public benefit entity of your choice.',
    ],
    noAutomatchMeds: 'No medicine available for booking, based on the needs on the "What have I asked for" page.',
    noAutomatchHygProds: 'No health products available for booking, based on the needs on the "What have I asked for" page.',
    emptyStatesNoNeeds: 'Register your needs for medicine and health products and here you will find out from which public benefit entities you can get them.',
    emptyStatesNoAutomatch: 'Right now the medicine and health products you are looking for are not available. Try again in a few days.',
    emptyStatesNoAutomatchOrNoNeeds: 'Here you will find the availability of the medicine and health products based on the needs you have declared. If you registered your needs and no results were found, try again in a few days.',
    map: 'Map',
    donationConditions: {
      title: 'Required documents',
      modal: {
        refugeeNote: 'If you are a refugee and have registered yourself at an NGO, ask for their help in contacting the public benefit entity.',
        title: 'The documents the {organization} needs to provide you with medicine and health products.',
      },
    },
    reserveProduct: {
      button: 'RESERVATION',
      success: 'The social pharmacy {orgName} has been updated ( check your email). The reception of the products should be made in a maximum of three working days. Have the necessary documents with you.',
    },
  },
  satisfiedNeeds: {
    title: 'What did I receive',
    emptyStatesMeds: 'You have not yet claimed the receipt of any medicines. If you have received medicines with the help of MEDforU, please go to the page “What have I asked for” and claim the medicines you have received!',
    emptyStatesHygProds: 'You have not stated that you have received any health product so far. Go to the "What have I asked for" page and state if you\'ve received a box!',
  },
  map: {
    title: 'Map of Donation spots',
  },
  faq: {
    title: 'FAQ',
    content: [
      {
        question: 'How can I contact someone to ask questions regarding MEDforU?',
        answer: `<p>Send an email at hello@givmed.org to contact GIVMED, the non profit organization that created MEDforU.</p>
            <p>A member of our team will respond as soon as possible.</p>
            <p>Contact hours: Monday to Friday, 10:00 - 17:00.</p>
            <p>Spoken languages: Greek and English.</p>
        `,
      },
      {
        question: 'Where should I go to receive the medicine?',
        answer: `<p> The medicines are donated by public benefit entities. You can find their address through the "MEDforU" application on the "Search Results" page .</p>
            <p>GIVMED, the non-profit organization that created MEDforU, does not store or re-distribute medicine.</p>
        `,
      },
      {
        question: 'Do I have to be registered with an NGO to receive the medicine?',
        answer: `<p> Ideally, an NGO should coordinate the collection of your medicine from a public benefit entity where you have found that they are available from MEDforU.</p>
            <p>If you are not registered, contact us either to find an NGO or to contact the public benefit entity.</p>
            <p>In any case you must have a doctor's prescription.</p>
        `,
      },
      {
        question: 'Do I need a doctor\'s prescription?',
        answer: `<p> A doctor's prescription is necessary if you need prescription medicines.</p>
            <p>If you need non-prescribed medication, a prescription is not necessary.</p>
        `,
      },
    ],
  },
  tutorial: {
    title: 'Tutorial',
    mainTitle: 'Do you want to learn step by step how to use our MEDforU app? Watch the video below!',
    iframe: {
        title: 'MEDforU tutorial video',
    },
  },
  terms: {
    title: 'Terms of use',
    content: [
      {
        header: 'TERMS OF USE GIVMED',
        text:
          'The company GIVMED Share medicine Share life (from now on the «Company») is operating in the field of making good use of leftover medicines to benefit socially vulnerable groups. The company created and manages the application MEDforU in order to enable people that belong in socially vulnerable groups to have access to medicine they need. By using the application MEDforU, you agree with the following terms of use that govern the use of this application. Given that in order to use the application you need to accept the terms of use, please read them carefully. Using this application, means that you accept all the terms of use.',
      },
      {
        header: 'INTELLECTUAL PROPERTY',
        text:
          'This application, including any information, signs or logos of the Company, is the intellectual property of the company that is protected by national and European laws that are in effect, regarding intellectual property (N.2121/1993, 2000/31/EK and 2001/29/EK.',
      },
      {
        header: 'PROTECTION OF PERSONAL DATA',
        text:
          'The company is committed to a high level of protection of personal data of the user, in accordance with the commands of N. 4624/2019, the Agreement of the European Union 679/2016 for Protection of Personal Data and related provisions of law. The company has authorized an Athens lawyer Responsible for Protecting Personal Data for any issue in relation to protecting personal data of the user of the application as it is defined in the Agreement of the European Union 679/2016 for the Protection of Personal Data.',
      },
      {
        header:
          'COLLECT OF PERSONAL DATA DURING REGISTRATION OF THE USER IN THE APPLICATION',
        text:
          'The company collects personal data of the user after explicit consent of him during registration that occurs after downloading the application. The user can access the content of these terms of use before making his registration in the application. By explicit acceptance of the terms of use and explicit consent of the user for collecting personal data, the application proceeds in collecting personal data of the user that are needed for proper use of the application. Collecting the following data is a requirement in order to be able to use the application through which the donation of medicine from public benefit entities to the users is possible. User’s data are being stated by himself and by explicit consent during his registration to the application, according to the undermentioned indications.',
      },
      {
        header: 'PERSONAL DATA THAT WE COLLECT',
        text:
          'During registration, the user enters the following data: e-mail, sex, year of birth, and area where they are looking for offered medicine. After that, the user registers in the application the needed medicine that he aspire to gain access to and receives information about donation availability from collaborating public benefit entities. The application saves the medicine needs that the user has registered in order to interlink them successfully with public benefit entities from where they can receive the medicine. The company does not in any way collect data of the users that are not referred above. ',
      },
      {
        header: 'KEEPING ARCHIVE OF PERSONAL DATA OF THE USERS',
        text:
          'Specifically, the company keeps an archive of all personal data stated above in accordance with the commands of N. 4624/2019 on protecting data of personal character and the Agreement of European Union 679/2016 for Protection of Personal Data. For keeping this data the company has proceeded in announcing before the Principle of Protecting Personal Data and respects every legal and technical measures for securing and protecting this data. Furthermore, the company maintains a permission of keeping the archive of the sensitive personal data which has published the Principle of Protecting Personal Data with registration number a 1790/2017. ',
      },
      {
        header: 'User’s Rights Regarding his Personal Data',
        text:
          'User has the right to edit, change or update the certified personal data, or to request the full and definitive removal of the registered personal data in the app at any time, through the specific communication form «Communication Form for the Personal Data of the User». For any question, request, or issue that is related to collecting, editing and keeping the personal data,users can communicate directly with the Personal Data Protection Authority to the email address <a class=\'default-link\' href=\'mailto:dpo@givmed.org\'>dpo@givmed.org</a>. The Company informs users in cases of violation of the security software with specific message that is sent to every user individually within 24 hours from informing the Company for the violation and  which informs explicitly for the personal data being violated, the following actions and the technical measures being taken accordingly.',
      },
      {
        header: 'USE OF USER’S PERSONAL DATA FROM THIRD PARTIES',
        text:
          'The personal data that the user certifies during his registration and later during using the GIVMED application are only meant for safekeeping the operation of the services, and the coordination of the donation of the user’s leftover medicine. They are not shared with third parties unknown to the user without his permission. We point out that we may share user\'s information with partners in order to support the application and after definitive consent of the user that is being given through the application. The user  while  accepting these terms of use  during the conclusion of his registration is giving the definitive, specific and free consent, on collecting the personal data stated above and keeping file of them for the service of the application\'s goals and operation, while maintaining the right to access and displaying objections for editing after submitting the request regarding the correction,temporary use, binding, not sharing or definite and complete deletion in accordance with N. 4624/2019 and the Regulation of European Union 679/2016 for Personal Data Protection. ',
      },
      {
        header: 'ACCESS AND NETWORK DEVICES',
        text:
          'The user is responsible to ensure the needed access to the data network in order to use the application. You are responsible for obtaining and updating the compatible hardware or devices which are required to access and use the services and the applications and their occasional upgrade/updating ',
      },
      {
        header: 'ΝΟTIFICATION',
        text:
          'The Company may provide notice with a general notification regarding its application through email, to your email address located in your account.You can contact the Company by written communication at the address, Viktoros Ougo 15, Metaxourgio, Attica, Greece or through e-mail <a class=\'default-link\' href=\'mailto:hello@givmed.org\'>hello@givmed.org</a>.',
      },
      {
        header: 'Liability limitation',
        text:
          'The Company intends to coordinate through this application the donating process of medicines between the user and the voluntary organizations and it does not carry any responsibility for the medicine donations that will occur through the use of the application. Furthermore, the Company is not responsible for the accuracy of the data and information entered by the user, who is the only one responsible for its accuracy. The Company does not provide in any case guarantees concerning the quality of the donated medicines and bears no responsibility in the occasion of damage to a third party by the possible use of the medication. Finally, under no circumstances shall the Company be held liable for any claims of legal, civil or/and criminal nature or for any damage caused by users of the application or by third parties for any reason related to the operation or use of this application and the entire medicine donation process that occurred through it. ',
      },
      {
        header: 'Condition of use',
        text:
          'This application should not be used by persons who have not reached the 18th years of age.It is expressly stated that the Company bears no liability regarding the accuracy of the user\'s reported date of birth at the time of registration and shall not be liable for the use of the application by persons who have not reached the age of 18.',
      },
      {
        header: 'JURISDICTION',
        text:
          'The user is explicitly acknowledging that the exclusive jurisdiction and competence of any dispute with GIVMED, is vested in the Courts of Athens and is implementing the Greek common law.',
      },
    ],
  },
  contact: {
    title: 'Contact',
  },
  about: {
    title: 'Info about medicines',
    content: [
      {
        question: 'What exactly is medicine?',
        answer: `<p>Medicine is substances, chemical or herbal, that have been scientifically proven to be effective and safe, and are taken or given to the body with the aim of:</p>
            <ul>
            <li>
                The treatment of a disease or a pathological condition (e.g., antibiotics are taken to treat a bacterial infection).
            </li>
            <li>
                Treating or relieving the symptoms of a chronic or non-chronic illness (e.g., asthma medications, painkillers, etc.)
            </li>
            <li>
                Prevention of a disease (e.g., the flu vaccine).
            </li>
            <li>
                Reducing the progression of a disease (e.g., drugs for dementia).
            </li>
            <li>
                The diagnosis of a pathological condition (e.g., contrast tomography).
            </li>
            </ul><p>`,
      },
      {
        question: 'Prescription and non-prescription medicines',
        answer: `<p><b>Prescription medicines (SY.FA.)</b> are those that in order to buy you need to have a prescription from a doctor [1]</small>.</p>
            <b>Non-prescription drugs (NSAIDs)</b> are those that you can buy without the need for a doctor's prescription, are usually taken for a short period of time, and are mainly for mild conditions that you can deal with on your own (e.g., x. headache pills).
            <br><br> <p><small>[1] For the possibility of a free prescription, you can contact your Municipality to inform you if it is done in the Municipal Clinics and if it concerns only specific groups of the population or all citizens.</small></p>`,
      },
      {
        question: 'Original and generic medicines',
        answer: `<p><b>An original medicine</b> is one that is produced and sold by the pharmaceutical company that created it from the beginning. When a pharmaceutical company discovers a new medicine, it has for some years the exclusive rights to produce and sell it ("patent"), ie no other pharmaceutical company can manufacture and sell a corresponding medicine with the same active ingredient (the component of the medicine due to its action in the body (see "What is the active substance"). After some years (20-25) the patent expires, thus allowing other pharmaceutical companies to develop and sell medicines containing the same active substance as the original medicinal product and which are called generics.</p>
            <b>Generic medicines</b> meet the same strict quality, safety, and efficiency standards as the originals. In this case, many medicines with different brand names may be released, but the active substance they contain is the same (even the form and dosage). Generic medicines may differ from the original medicines in form, size, shape, color, packaging, and inactive components (called excipients), ie those components that do not contribute to the action of the medicine. However, they are used to treat the same conditions as the originals and are just as effective. Generic medicines cost less than the original ones because, obviously, the pharmaceutical companies that sell them have not spent money on their research and development. The decision about which medicine to take and whether it is original or generic should always be made under the guidance of your doctor or pharmacist.`,
        },
      {
        question: 'What is the active substance of a medicine?',
        answer: 'The active substance of medicine is the ingredient that allows it to have a therapeutic effect on the human body, that is, makes it effective in the treatment of the disease or condition for which we take it, or to have a medicinal effect, such as the medicines we take. to do a diagnostic test (eg the contrast medicine given before the CT scan). The dosage recommended by your doctor or pharmacist for each medicine is essentially the amount of active substance you need to take to be able to have the desired effect on improving your health. The warnings that exist, also, for possible side effects in all medicines, are those that can in some cases be caused by the active substance of each medicine. ',
      },
      {
        question: 'What are antibiotics and what is their correct intake?',
        answer: `<p><b>What are antibiotics?</b></p><p>Germs include bacteria, viruses, fungi, and parasites. Antibiotics have been developed to treat infections caused exclusively by bacteria by killing them or stopping them from growing. Antibiotics do not work against all bacteria, which means that not all of them are suitable for treating any type of bacterial infection. Some aim to treat a single class of bacteria, while others aim to treat more than one. However, antibiotics are by no means effective against infections caused by other types of germs, such as viruses (such as the flu or the common cold).</p>
        <p><b>What is antibiotic resistance (or antimicrobial resistance)?</b></p><p>Antibiotic resistance is not related to the human body, that is, our body does not become resistant to antibiotics. It means that the bacteria develop the ability to overcome the medicines that are designed to treat them, so they are not killed and continue to grow despite taking them. Consequently, the bacterial infection in our body is not cured. These bacteria were previously sensitive to the antibiotics that were created to fight them, but they eventually managed to genetically alter (mutate) and develop resistance to them.</p>
        <p><b>Why does antibiotic resistance occur?</b></p><p>Developing resistance of bacteria to certain antibiotics is a natural development that can occur. However, when antibiotics are taken unnecessarily, excessively, and inappropriately, this process becomes much faster and to a greater extent. That is, bacteria are created for which there are no antibiotics available that can treat them. This means that while in the past some infections were treated with certain antibiotics, now they are becoming dangerous to humans again, as the antibiotics that were available to treat them are no longer effective.</p>
        <b>What to do & not to do about antibiotics</b></p><p>The responsible and correct intake of antibiotics helps to reduce the phenomenon of resistance so that these medicines remain effective in the treatment of bacterial infections. That is why it is very important to follow the following basic instructions:</p>
        <p><ul>
            <li>
                <b>Only take antibiotics when recommended by your doctor. Antibiotics can only be obtained with a prescription.</b>
            </li>
            <li>
                Follow your doctor's instructions exactly for the type of antibiotic you will be taking, the dosage, and the duration you will be taking it.
            </li>
            <li>
                Common infections caused by viruses (not bacteria), such as whooping cough, cough, sore throat, bronchitis, otitis, sinusitis, etc. They are NOT treated with antibiotics.
            </li>
            <li>
                Never use antibiotics that you may have left at home from previous treatment or on the advice of relatives and friends. Only a doctor can decide if you should take an antibiotic. Taking antibiotics when you should not do more harm to your health than good.
            </li>
        </ul>,<p>`,
      },
      {
        question: 'What are medication compliance, drug overdose, sub-therapeutic dose, and multi pharmacies?',
        answer: `<p><b>Compliance with the medication:</b> To faithfully follow the medication prescribed by the doctor and the relevant instructions for taking it, even without the close supervision of the doctor.</p>
            <p><b>Medicine overdose or abuse:</b> Take a higher dose of medicine than your doctor prescribes and it is recommended that you take it. This can be done by mistake, for example in case someone forgets that he took the dose of the medicine and takes a second one.</p>
            <p><b>Hyper therapeutic dose:</b> Take less medicine than your doctor prescribes and it is recommended to take or skip doses.</p>
            <p><b>Multitherapy:</b> Taking multiple medications at the same time, prescription or not. It can lead to mistakes in taking and dosing and to harmful interactions between them.</p>`,
      },
      {
        question: 'What are the side effects or bad side effects of medicine?',
        answer: `<p>They are unwanted or unexpected symptoms that can be observed after using a medicine. These side effects can be mild or even severe to the point that they can be life-threatening (eg dizziness, nausea, confusion, headache, dry mouth, shortness of breath, rash, allergic reaction, damage to organs of the body, etc.). These include those known to be caused by the use of a medicament e.g., according to the approved indications (and therefore mentioned in the package leaflet of the medicine), and those caused by other events, such as overdose, misuse, abuse, etc. If you suspect that a medicine may have caused you some side effects contact your doctor or pharmacist directly for information.</p>
        <p><b>CAUTION: Do not stop your medication unless your doctor tells you to.</b></p>
        <p>It does not necessarily mean that the medicine or the active substance of the medicine causes the side effects or that the medicine cannot be used safely. Whether or not you will continue your medication and how this will be done will only be decided by your doctor. The National Medicines Agency (EOF) has created the "Yellow Card" as a means of gathering information on the side effects of drugs. The "Yellow Card" is accessible via the electronic link https://www.eof.gr/web/guest/yellowgeneral. If you want to alternatively fill in the "Yellow Card" in printed form, you can contact the Department of Side Effects of EOF (284 Mesogeion, 155 62, Athens or the contact numbers 213 2040380 and 213 2040337, Fax 210 6549585) or directly to your pharmacy. The "Yellow Card" can be completed by both health professionals and patients themselves. So do not hesitate to mention side effects that are unknown to you and are not listed in the package leaflet of this medicine or if you have any doubts about whether they are related to any medicine you are taking. The information you provide can be very important to make known a side effect of medicine so that steps can be taken to ensure that patients take it more safely and safely. If you have difficulty filling out the "Yellow Card" yourself, ask your doctor or pharmacist for guidance.</p>`,
      },
      {
        question: 'How can medicine provoke interactions?',
        answer: `<p><b>With other medicines</b></p><p>It occurs when two or more medicines react with each other and cause unexpected actions. If you are taking different medicines and / or you are being watched by more than one doctor, you should know both you and all of your medication that you are taking to avoid any interactions between medicines.</p>
        <p><b>With food / beverages</b></p><p>Food can affect how the body absorbs a medicine. Also, concomitant intake of alcohol and certain medicines can reduce their effectiveness and / or cause side effects such as fatigue and reduction in the speed of reflexes. So it is important to follow closely the instructions for taking a medicine you will receive from your doctor or pharmacist.</p>
        <p><b>With existing diseases</b><p><p>The existence of a disease may not allow certain medicinal products to be taken, as it can make it dangerous for your health. This means that your doctor is important to know, except all your medication, and your whole medical history.</p>`,
      },
      {
        question: 'How can I get my medicine right?',
        answer: `<p>Some medications will need to be taken for a period of time (until you are actually cured of the condition you are taking them for), while in other cases you may need to take them for the rest of your life (as is often the case with chronic illnesses, e.g..x. diabetes). Therefore, proper medication intake is very important for your health. If you do not follow your doctor or pharmacist's instructions on how to take a medicine, it may reduce its effectiveness and ultimately not help you or end up hurting your health.</p>
        <p>That is why it is very important to:</p>
        <p><ul>
          <li>
            take exactly the dose recommended by your doctor or pharmacist.
          </li>
          <li>
            take the medicine at the hours and days recommended by your doctor or pharmacist.
          </li>
          <li>
            take the treatment for the period of time recommended by your doctor or pharmacist (no more, no less)
          </li>
          <li>
            follow the instructions for taking the medicine given to you by your doctor or pharmacist as to whether or not you should take this medicine with or without food and if you should avoid taking certain foods or drinks for as long as you take it.
          </li>
          <li>
            do not take expired medicines.
          </li>
          <li>
            keep in mind that some medicines, if you open the package (eg eye drops), then expire earlier than the expiry date stated on the package (in which case the expiry date after opening is indicated on the package leaflet medicine).
          </li>
          <li>
            talk to your doctor or pharmacist for guidance on what to do if you miss a dose, do not decide for yourself.
          </li>
          <li>
            do not crush tablets to make powder or open capsules to make them easier to swallow unless your doctor or pharmacist tells you that this is safe for this medicine.
          </li>
          <li>
            do not ignore an unexpected side effect that you think may be related to a medicine you are taking, contact your doctor or pharmacist directly.
          </li>
        </ul></p>
        <p>It is also important not to give friends and relatives medicines prescribed by your doctor for you, as you may not know exactly what they are suffering from and whether the medicine you are taking will help or harm them. Only the doctor who examines them can decide which medicine they should take, what is the right dose, and for how long they should take it. Similarly, do not use medicines that have been prescribed for your relatives or friends. For more information see the section "What can you do with expired medications but no longer need them".</p>`,
      },
      {
        question: 'How to organize the medication I am taking?',
        answer: `
        <ul>
          <li>
            Use a medication adjustment box for the whole week, which will help you organize the medications you take on a daily basis. This box can be obtained from any pharmacy.
          </li>
          <li>
            If the time your doctor or pharmacist tells you to take a medicine coincides with another time, combine the two actions (eg take the medicine as soon as you brush your teeth every morning).
          </li>
          <li>
            Stick notes with reminders to take the medicine in places you see all the time, such as the bathroom mirror or the refrigerator door.
          </li>
          <li>
            Use a diary to keep track of each time you take your medicine and the dose you take.
          </li>
          <li>
            Make reminders on your cell phone to let you know the days and times you need to take your medicine.
          </li>
          <li>
            If possible, ask a family member or friend to help you, reminding you when you need to take your medicine.
          </li>
          <li>
            Talk to your doctor or pharmacist for guidance on what to do if you miss a dose, do not decide for yourself.
          </li>
          <li>
            Do not crush tablets to make powder or open capsules to make them easier to swallow unless your doctor or pharmacist tells you that this is safe for this medicine.
          </li>
          <li>
            Do not ignore an unexpected side effect that you think may be related to a medicine you are taking, contact your doctor or pharmacist directly.
          </li>
        </ul>
        `,
      },
      {
        question: 'What do I do before I start taking medicine?',
        answer: `<p>Inform your doctor or pharmacist:</p>
        <p><ul>
          <li>
            For any other medicines, you are taking, even if they are herbal, vitamins, or dietary supplements. Ask if you can take them with your new medicine or if you need a change. You will thus be able to avoid any interactions between the various medications that may be detrimental to your health.
          </li>
          <li>
            If you have a proven allergy to certain medications.
          </li>
          <li>
            If you have a surgery scheduled.
          </li>
          <li>
            If you are pregnant or breastfeeding.
          </li>
          <li>
          If there is something you do not understand about the purpose, method of administration, dosage, or any other relevant information about the medicine.
          </li>
        </ul></p>
        <p>To have a good picture of all the medicines you use, but also to be able to inform your doctor or pharmacist at any time, it is very useful to make a list of all the medicines you are taking. A table, ie for each medicine that could have columns:</p>
        <ol>
          <li>
            Drug Name
          </li>
          <li>
            Why am I taking the medicine?
          </li>
          <li>
            Form (eg pill, drops, syrup, patch, injection, etc.).
          </li>
          <li>
            Dose (eg 1mg, 20ml, etc.).
          </li>
          <li>
            How often should I take it & how many doses.
          </li>
          <li>
            Date I started taking it.
          </li>
          <li>
            Date I should stop taking it (if I have a doctor or pharmacist).
          </li>
          <li>
            Other instructions on how to take it (eg with food or fasting / or should not drink alcohol, etc.).
          </li>
        </ol>`,
      },
      {
        question: 'What should I ask my doctor or pharmacist before I start taking medicine?',
        answer:
        `<ul>
            <li>
                What does this medicine do?
            </li>
            <li>
                What will it help me with?
            </li>
            <li>
                Why should I take it?
            </li>
            <li>
                Does it replace any of the medications I am already taking?
            </li>
            <li>
                When and how should I take it? How much will I get each time?
            </li>
            <li>
                How long will it take me to get it?
            </li>
            <li>
                Can I chew, crush or melt this medicine to get it?
            </li>
            <li>
                When will I start to see some improvement? How do I know I'm getting better?
            </li>
            <li>
                Do I need to do any tests to see if the medicine really helps me? If so, what are these tests and when should I take them?
            </li>
            <li>
                What should I do if I miss a dose?
            </li>
            <li>
                What should I do if I accidentally take more medicine than I should?
            </li>
            <li>
                Are there any possible side effects of taking this medicine?
            </li>
            <li>
                Do I need to avoid certain medications or supplements for as long as I take this medication?
            </li>
            <li>
                Do I need to avoid certain foods, drinks, or activities (eg driving) for as long as I take this medicine?
            </li>
            <li>
                What is the best way to store this medicine?
            </li>
            <li>
                Is there a case for me to develop tolerance or dependence on this particular drug?
            </li>
            <li>
                Does my insurance cover this medicine?
            </li>
        </ul>`,
      },
      {
        question: 'How do I store my medicines?',
        answer: `<p>Each medicine is different, so always ask your doctor or pharmacist how to store it and read the package leaflet (FOX) carefully.</p>
        <ul>
          <li>
            As a general guideline, store medicines in a cool, dry place out of direct sunlight, as they can be harmful. That's why it's not good to keep medicines in the bathroom, kitchen, or car, where it is hot and humid.
          </li>
          <li>
            Some medicines may need to be stored in the refrigerator. Especially insulin, vaccines, and some eye drops and syrups require specific temperature conditions not only when stored but also when transported from the pharmacy to your home. Therefore, for these classes of medicines, follow faithfully and with special care the instructions that the pharmacist will give you both for their transport and their storage.
          </li>
          <li>
            Always keep the medicines in their original packaging, because it has the expiration date of the medicine on it and the package leaflet inside.
          </li>
          <li>
            Do not put different medicines in the same container.
          </li>
          <li>
          <p>Do not use pills or tablets if they are damaged, ie:</p>
            <ol>
              <li>
                are fragile or have disintegrated.
              </li>
              <li>
                have discolored.
              </li>
              <li>
                are soft (while not normally so).
              </li>
              <li>
                creams that have changed their texture, color, and/or smell bad.
              </li>
              <li>
                Keep medicines in a safe place out of the reach and sight of children (see Parenting Tips).
              </li>
          </li>

        `,
      },
      {
        question: 'What should parents be aware of about their medications and children?',
        answer: `<p><b>Put the medicines and vitamins you have at home in a safe place that children do not see and cannot reach.</b></p><p>Find such a place in your home and store there all the medicines and vitamins you have, even the ones you use daily. Do not scatter them in different parts of the house and do not leave them in common view and in places that could be reached by small children. The medicines we leave on benches, tables, nightstands, bags, or wallets can be easily accessed by young children, who have an innate curiosity about everything around them and put all sorts of things in their mouths.</p>
        <p><b>If there is a safety cap on the package, make sure you always close it properly after each use.</b></p><p>If there is a safety cap on the medicine package, turn it over until you hear a "click" feature. Even so, this does not mean that children could not open the package. So again even these medicines should be kept together with all the rest away from children, as mentioned above, and never given to them to keep as a toy even if it is for a while.</p>
        <p><b>Pay special attention to the dosages of the medicines you give to the children.</b></p><p>You are well aware of the dosage of the medicine you are going to give your child. Follow the instructions of your pediatrician or pharmacist carefully and read the package leaflet carefully. If the medicine is in liquid form, there is usually a dose-measuring instrument (teaspoon, cup, or syringe) already in the package. If not, ask your pharmacist to give you one that is right for you. Do not use spoons, as they come in different sizes and are not suitable for this purpose, so you may end up giving your child a smaller or larger dose of medicine than they should. If the child is unable to take the full dose at some point (because he is crying, spitting on some of it, vomiting after a while, etc.), do not give him a second dose directly even if you have doubts about whether managed to get the dose he needed. Talk to your pediatrician to guide you on what is best to do in each case.</p>
        <p><b>Discuss with your children about medicines and how they are used safely</b></p><p>Discuss with your children and explain calmly and logically what the medicine is, why we take it and why they should only take it when given to them by an adult in the family. Do not tell your children that medicines are candies to persuade them to take them. Many drugs are brightly colored and may look like candy to their eyes, making the test even more tempting. Teach older siblings that they should never give medication to younger siblings before first talking to an adult in the family.</p>
        <p><b>Ask guests and guests to follow the same rules.</b></p><p>Remind guests and guests who have medicines with them that they should also keep them, while they are at home, in a safe place that small children do not see or reach.<p>
        <p><b>Be prepared in case of an emergency.</b></p><p>Always have the emergency number (166) and the Poison Center (2107793777) available and call immediately if you think your child may have taken any medicine or vitamins on their own.</p>`,
      },
      {
        question: 'What should people taking care of other people\'s medicines watch out for?',
        answer: `<p><b>Get to know all the medication he is taking and plan how he will take it.</b></p><p>Make a list of all prescription and over-the-counter medications, including herbal remedies and supplements. If for some reason you do not take a dose of the drug, do not give a double later or try to make up for it in some way. Talk to your doctor or pharmacist for advice on what to do in each case depending on your medication. Use a medication adjustment box for the whole week, which will help you organize daily the medications that the person you care for needs to take. You can also use a diary to more easily remember when to take each medication and dosages, as well as important appointments with the attending physicians. Medications should also be given at certain times of the day and in some cases should be combined with food, while others should not. If you do not follow the exact instructions of your doctor or pharmacist to take the medicine correctly, its therapeutic effects may be reduced.</p>
        <p><b>Seek regular communication and cooperation with your doctor and pharmacist.</b></p><p>For any new medicine, you need to take, first ask your doctor or pharmacist if it is safe in combination with all the other medicines he is taking. Important questions to ask your doctor or pharmacist about any new medicine you are about to take are:</p>
        <p><ul>
          <li>
            What is the purpose of this medicine?
          </li>
          <li>
            What improvement in the health of the person I take care to expect and in how long will it happen?
          </li>
          <li>
            When and how should he take this medicine, what is the dose, and for how long will he take it?
          </li>
          <li>
            What do I do if I miss one or more doses?
          </li>
          <li>
            Can he take this medicine in combination with the rest he is taking?
          </li>
          <li>
            Are there any possible side effects that may occur and what are they?
          </li>
          Make sure you keep in touch with the doctors who are watching him/her and arrange the appointments he/she needs with them so that they can review the medication at regular intervals and adjust it if necessary. For long-term medication, be sure to renew your prescriptions in time before your home medication is completely exhausted so that you do not miss any of the doses. At your doctor's appointment, discuss whether the person you are caring for is improving and if he or she has had side effects or new symptoms that he or she has not had before.
        </ul></p>
        <p><b>ΤWhat to do if he refuses to take the medicine.</b><p><p>If the person you take care, refuses to take his medication, try to understand exactly where the problem lies. In other words, this medicine may cause some side effects that it wants to avoid or it may be difficult for you to swallow a large pill. Do not try to give him the medicine by force. Avoid crushing tablets to turn them into powder or opening capsules to mix them with a drink or food, as this may not be safe. Consult your doctor or pharmacist about what to do in these cases. For example, they may suggest another drug that is just as effective but is in liquid form, so that it is easier to swallow.</p>`,
      },
      {
        question: 'Where do I throw my medicines when they expire?',
        answer: `<p>Expired medicines should NOT be discarded:</p>
        <p><ul>
          <li>
            in the neighborhood rubbish bins
          </li>
          <li>
            in the sink
          </li>
          <li>
            in the toilet bowl,
          </li>
        </ul></p>
        <p>because the chemicals they contain pollute both the soil and the water in the environment, posing a significant risk to everyone's health. Expired medicines should only be thrown away in the green metal bins found in pharmacies. There they are temporarily collected and handed over to the Institute of Pharmaceutical Research and Technology (IFET) to be safely destroyed.</p>
        <p><b>What can you throw in the green bins of pharmacies?</b></p>
        <p><ul>
          <li>
            Pills and capsules in the foil containing them (paper and plastic packaging of medicines should be disposed of in recycling bins).
          </li>
          <li>
            Well-sealed glass bottles of syrups and vials for injection.
          </li>
          <li>
            Tubes with ointments and creams.
          </li>
        </ul></p>
        <p><b>What you should NOT throw in the green bins of pharmacies.</b></p>
        <ul>
          <li>
            Mercury (eg thermometers)
          </li>
          <li>
            Syringes
          </li>
          <li>
            Cracked injection vials
          </li>
          <li>
            Broken syrup bottles
          </li>
          <li>
            Gauze
          </li>
          <li>
            Cotton
          </li>
        </ul>`,
      },
      {
        question: 'What should I do with my surplus medications?',
        answer: `<p>If you have medicine that is about to expire or you have leftovers because your treatment is over, you can donate them to other people who need them, even if you have a small amount left.</p>
        <p>Το <b>GIVMED</b> is a non-profit organization that aims to give people immediate access to the medicines they need. You can easily and quickly donate the medicines you no longer need to social pharmacies, nursing homes, and other charities.</p>
        <p>At the <b>GIVMED</b> website <a href="https://givmed.org/" target="_blank">(https://givmed.org/)</a> you can find the charities that are near you and need the medicines you wish to donate. If you are unfamiliar with the technology, you can call GIVMED (210 3007222) for directions.</p>
        It is important that the medicine or medicines you wish to donate meet the following conditions:
        <p><ul>
          <li>
            The expiration date has not passed.
          </li>
          <li>
          It is not a medicine that needs a refrigerator.
          </li>
          <li>
          It is not open if it is a syrup, ointment, drops, or inhaled product.
          </li>
          <li>
            It does not belong to the category of digram drugs.
          </li>
          <li>
            It has been stored at a controlled room temperature of 25 degrees and away from any kind of heat or humidity source.
          </li>
          <li>
            The medicine voucher (i.e. the barcode sticker) has been removed.
          </li>
        </ul></p>
        <p><b>Do not forget that medicine is a good to which all people have the right to be able to take care of their health in the best possible way. If you have any further questions or concerns, do not hesitate to talk to your doctor and pharmacist, who are best placed to assist you and guide you through what you need to know about your medication and take care of your health.</b></p>`,
      },
    ],
  },
  logout: {
    title: 'Sign out',
  },
  organizationIsClosed: 'Closed',
  weekdays: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
  },
  quantityType: {
      BOX: 'Box | Boxes',
      BAG: 'Bag | Bags',
      PIECE: 'Piece (box sub-unit) | Pieces (box sub-units)',
      OTHER: 'Other | Other',
      ML: 'ml',
      pieces: 'Piece | Pieces',
      subUnit: '(box sub-unit) | (box sub-units)',
  },
  quantityTypePlural: {
      BOX: 'Boxes',
      PIECE: 'Pieces (box sub-unit)',
      OTHER: 'Other',
  },
  labels: {
    lang: 'Language',
    address: 'Address',
    phone: 'Phone',
    medName: 'Medicine name',
    medSubs: 'Active substance',
    hygProdName: 'Health product name',
    hygProdCategoryOrSubCategory: 'Category | subcategory name',
    subCategory: 'Subcategory',
    expDate: 'Expiration date',
    quantity: 'Quantity',
    productUnit: 'Product unit',
    needDate: 'Registration date',
    concentration: 'Concentration',
    boxes: 'Box | Boxes',
    newSearch: 'New search',
    newNeed: 'Add new need',
    checkMore: 'Check more',
  },
  organizationDocuments: {
    annual_salaray_adjustment: 'Annual salary adjustment slips',
    proof_of_residence: 'Proof of residence (eg DEKO account, lease)',
    doctor_prescription: 'Doctor\'s prescription',
    family_status_certificate: 'Family status certificate',
    vulnerability_certificate: 'Any certificate of vulnerability (e.g., disability rate, large families, etc.)',
    id_or_passport_copy: 'Copy of ID or passport',
    annual_tax_application: 'Annual tax application (E1)',
    unemployment_card: 'Unemployment card',
    real_estate_declaration: 'Real estate declaration (E9)',
    insurance: 'Formal declaration that there is no insurance at any of insurance institutions. For Insured persons, it is required the submission of the first page of their insurance booklet.',
    social_solidarity_income: 'If the patient is a beneficiary of the Social Solidarity Income (KEA) only the presentation of their ID and an approved KEA application is required',
    application: 'Application (issued and completed at the institution',
    valid_residence_permit: 'Valid residence permit (for foreigners)',
    municipality_certificate: 'In the case of non-residents, a certificate from the municipality is required that resides that they are not served by a corresponding program.',
    pension_scrap_or_salary_certificate: 'Pension scrap or salary certificate',
    photograph: 'Photograph(passport style)',
    birth_certificate: 'Birth certificate (for minors)',
    vat: 'AFM',
  },
  newAppVersionAvailable: {
    text: 'New version available! Click to update',
    btn: 'Update',
  },
  offlineMode: {
    text: 'You are currently offline',
  },
  iosInstallBanner: {
    text: 'Install MEDforU on your phone. Tap the share button and then select \'Add to homescreen\'.',
  },
  errorCodes: {
    serverConnectionProblem: 'No connection to server. Please contact us!',
    generalValidationError: 'Wrong input.',
    multipleObjectsReturned: 'Multiple objects returned.',
    accountDisabled: 'User account has been disabled.',
    userAlreadyExists: 'User with this email already exists.',
    wrongCredentials: 'Email or password fields are incorrect.',
    validationErrorException: 'An exception occurred.',
    invalidToken: 'Invalid token',
    wrongCurrentPassword: 'Wrong current password',
    authenticationFailed: 'Authentication failed.',
    coveredQuantity: 'Covered quantity greater than quantity in need.',
    sendEmailException: 'An error occurred while trying to send email to user.',
    transactionIntegrityError: 'Transaction integrity error',
    productNotFound: 'This product is not founded.',
    productNotMatchedWithYourNeeds: 'This product is not matched with your needs.',
    requestedReservedQuantityGreaterThanPermitedAvailableQuantity: 'Requested reserved quantity of this product is greater than permitted available quantity.',
    noEmailSetForThisOrganization: 'There is not email set for this organization.',
  },
};
